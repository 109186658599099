export const urls = {
    USERS: 'users',
    MANAGE: 'manage',
    ORDER_BY_USER: 'orderByUser',
    SUPPORT: 'support',
    REPORTS: 'reports',
    TABLEAU: 'tableau',
    FILE: 'File',
    SIGN_UP: 'SignUp',
    ANNOUNCEMENT: 'announcement',
    GET_USERS_LIST: 'GetUsersList',
    GET_USER: 'GetUser',
    DISABLE_USER: 'DisableUser',
    ENABLE_USER: 'EnableUser',
    DELETE_USER: 'DeleteUser',
    GET_USER_DETAILS: 'GetUserDetails',
    SEARCH_COGNITO_USER: 'SearchCognitoUser',
    UPDATE_USER: 'UpdateUser',
    ONE_DRISCOLLS: 'OneDriscolls',
    UPLOAD: 'Upload',
    DOWNLOAD: 'Download',
    COGNITO: 'Cognito/',
    PERSONA: 'persona',
    ACTIVATE_USER: 'activateuser',
    DEACTIVATE_USER: 'deactivateuser',
    SUPER_ADMIN: 'superadmin',
    RANCHES: 'ranches',
    DISTRICTS: 'districts',
    COMPANIES: 'companies',
    BASIC_INFORMATION: 'basicinformation',
    PERSONA_ID: 'personaid',
    USER_OKTA_ID: 'useroktaid',
    OKTA_GROUP: 'oktagroup',
    COUNTRY: 'country',
    USER_LIST: 'userlist',
    DELETE_USER_FROM_GROUP: 'deleteuserfromgroup',
    PROFILE: 'profile',
    REQUEST: 'support/request',
    EVENTS: 'events',
    QA_REPORTS: 'qareport',
    REPORT: 'report',
    DASHBOARD: 'dashboard',
    AUTHID: 'authid',
    REQUESTOR_ID: 'requestorid',
    LANGUAGE: 'language',
    ENTITY: 'entity',
    ASSIGNMENT: 'assignment',
    SHAREPOINT: 'sharepoint',
    SITES: 'sites',
    ITEMS: 'items',
    DRIVES: 'drives',
    CHILDREN: 'children',
    CONTENT: 'content',
    SITE_NAME: 'sitename',
    SITE_ID: 'siteid',
    SUB_SITE_ID: 'subsiteid',
    REQUEST_TYPE: 'requesttype',
    LISTID: 'listid',
    TOP: 'top',
    SKIP_TOKEN: 'skiptoken',
    GOOGLE_ANALYTICS_REPORT: 'googleAnalytics/GetGaAnalytics',
    ITEMID: 'itemid',
    SHARED_DOCUMENTS: 'Shared Documents',
    LAYOUT: '_layouts/15/Doc.aspx?sourcedoc=',
    DEFAULT_ACTION: '&action=default&mobileredirect=true',
    SHAREPOINT_SEARCH: 'search',
    SHAREPOINT_DOWNLOAD: 'download',
    NOTIFICATION: 'notification',
    CATEGORY: 'category',
    UPDATE: 'update',
    PTIC: 'pti',
    PRODUCING_AREAS: 'producingareas',
    PRODUCING_AREA: 'producingarea',
    WAREHOUSES: 'warehouse',
    BERRYTYPE: 'berrytype',
    PRODUCING_AREA_CODE: 'producingareacode',
    RANCH_NUMBER: 'ranchnumber',
    GROWERS: 'growers',
    COMMODITY_CODE: 'commoditycode',
    HARVEST_ORDERS: 'harvestorders',
    DATE: 'date',
    WAREHOUSE_NUMBER: 'warehousenbr',
    START_ROW: 'startRow',
    COGNITO_USER: 'cognitouser',
    PAGE_SIZE: 'pageSize',
    LIST: 'list',
    LISTRANCH: 'listranch',
    GROWERNBR: 'growernbr',
    RANCHNBR: 'ranchnbr',
    MANUALHARVESTORDER: 'manualharvestorder',
    COMPANY: 'company',
    SUBTYPE: 'subtype',
    PTIAUDIT: 'ptiaudit',
    PTIAUDITID: 'ptiauditid',
    PRINTCOUNT: 'printcount',
    HARVESTORDER: 'harvestorder',
    ITEMNBR: 'itemnbr',
    PACKDATE: 'packdate',
    SUPPLEMENTAL_FILE: 'supplementalfile',
    VALID_GROWERS: 'validgrowers',
    FEATURES: 'features',
    PERSONAS: 'personas/persona',
    USER: 'user',
    INVITE:'invite',
};
