import { Call } from '@driscollsinc/driscolls-display-rules';
import { ButtonVariant, DriscollsLoadingButton, DriscollsRichText, DriscollsSpinner, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { AttachFile } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import ViewAttachments from 'components/announcement/ViewAttachments';
import DTextArea from 'components/shared/TextArea/TextArea';
import StatusDropDown from 'components/support/StatusDropDown';
import { FOLDER_NAMES } from 'constants/foldernames';
import ApplicationContext from 'context/ApplicationContext';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useDeleteAttachment from 'hooks/useDeleteAttachment';
import useGetApiTokens from 'hooks/useGetApiTokens';
import useGetAttachedFiles from 'hooks/useGetAttachedFiles';
import useGetResponseForm from 'hooks/useGetResponseForm';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import { NAMESPACE } from 'i18n';
import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import { isJsonString } from 'utils/helper';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import { ROUTES } from '../../constants/routes';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import APIEndPoints from '../../services/api';
import DriscollsToolTip from '../../ui/DriscollsToolTip/DriscollsToolTip';
import DuDateUtilities from '../../utils/DuDateUtilities';
import styles from './ResponseForm.module.css';

function ResponseForm(props) {
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    useCheckMasterData();
    const navigate = useNavigate();
    const location = useLocation();
    const [response, setResponse] = useState('');
    const path = location.pathname.split('/');
    const userLang = getCurrentLanguageSelected();
    const rowData = location?.state?.data ? JSON.parse(location.state.data) : {};
    rowData.RequestId = rowData.RequestId ? rowData.RequestId : path[path.length - 2];
    const [fileDetails, setFileDetails] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [responseLoading, setResponseLoading] = useState(false);
    const currentFile = useRef();
    const { getTokenAndId } = useGetApiTokens();
    const { currentResponse, loading } = useGetResponseForm(props.oktaAuth, rowData.RequestId);
    const { fetchSignedUrl, loading: fetchFileLoading } = useGetSignedUrl(props.oktaAuth);
    const { deleteAttachment, errorDeleteAttachment } = useDeleteAttachment(props.oktaAuth);
    const { fileURL, isFileLoading } = useGetAttachedFiles(
        props.oktaAuth,
        currentResponse.ResponseAttachmentURL + ',' + currentResponse.RequestAttachmentURL
    );
    const [status, setStatus] = useState(rowData.Status);
    const { setShowToast } = useContext(ApplicationContext);

    const sendResponse = async () => {
        setResponseLoading(true);
        const { token, userOktaId, userEmail } = await getTokenAndId(props.oktaAuth);
        try {
            const data = await Call(
                {
                    name: 'Response',
                    url: APIEndPoints.POST_SUPPORT_RESPONSE(),
                    method: 'POST',
                    options: {},
                    data: {
                        ResponseId: path[4] !== '0' ? path[4] : null,
                        RequestId: path[3],
                        ResponderAuthId: userOktaId,
                        ResponseBody: response,
                        Status: status || currentResponse.Status,
                        CreatedBy: userOktaId,
                        UpdatedBy: userOktaId,
                        UserName: userEmail,
                        UserLanguage: userLang,
                        AttachementURL: attachments.length > 0 ? attachments.map((itm) => itm).join(',') : '',
                        FileType: fileDetails.length > 0 ? fileDetails[0].fileFormat : ''
                    }
                },
                token
            );
            if (data.errors?.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            }
            navigate(-1);
            setShowToast({ toastMessage: 'Response sent successfully!' });
        } catch (error) {
            console.log('error', error);
        } finally {
            setResponseLoading(false);
        }
    };

    const deleteAttachmentHandler = (attachmentId) => {
        deleteAttachment(attachmentId);
        if (!errorDeleteAttachment) {
            setFileDetails(fileDetails.filter((file) => file.attachmentId !== attachmentId));
            setAttachments([]);
        }
    };

    const completeUpload = (uploadId) => {
        //This completes the upload process
        setAttachments([...attachments, uploadId + '.' + currentFile.current.fileFormat]);
        currentFile.current = { ...currentFile.current, attachmentId: uploadId };
        setFileDetails([...fileDetails, { ...currentFile.current, attachmentId: uploadId }]);
    };

    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const file = target.files[0];

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            console.log(e.target.result);
            const fileFormat = file.name.split('.').pop();
            currentFile.current = { fileName: file.name, fileFormat };
            fetchSignedUrl(file, file.type, file.name, FOLDER_NAMES.support, completeUpload);
        };
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.SUPPORT}`);
    };

    return (
        <DrcContainer heading="Resolution" goBackHandler={goBackHandler}>
            <Container hideRoute>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TypographyTranslated variant="subtitle2" nameSpacing="support" className={styles.subject}>
                                {'Raised By:'}
                            </TypographyTranslated>
                        </Grid>
                        <Grid item sx={{ display: 'flex', gap: 30 }}>
                            <Typography variant="subtitle2">{currentResponse.RequestCreatedBy}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TypographyTranslated variant="subtitle2" nameSpacing="support" className={styles.subject}>
                                {'Raised On:'}
                            </TypographyTranslated>
                        </Grid>
                        <Grid item sx={{ display: 'flex', gap: 30 }}>
                            <TypographyTranslated variant="subtitle2">
                                {DuDateUtilities.ToPrettyDateTime(currentResponse.RequestCreatedAt)}
                            </TypographyTranslated>
                        </Grid>
                    </Grid>
                    {showAttachmentFeature() === true && (
                        <>
                            <Divider className={styles.divider} />
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ViewAttachments fileURL={fileURL} isFileLoading={isFileLoading} />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Divider className={styles.divider} />
                    <Grid container spacing={2}>
                        <Grid item>
                            <TypographyTranslated nameSpacing="support" variant="subtitle2" className={styles.subject}>
                                {'Last Response'}
                            </TypographyTranslated>
                        </Grid>
                        <Grid item sx={{ display: 'flex', gap: 30 }}>
                            <TypographyTranslated variant="subtitle2">{currentResponse.ResponseBody}</TypographyTranslated>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                        <Typography className={styles.subject}>{currentResponse.RequestHeader}</Typography>

                        <div className={styles.responseStatusContainer}>
                            {currentResponse.RequestBody && isJsonString(currentResponse.RequestBody) ? (
                                <DriscollsRichText defaultValue={currentResponse.RequestBody} readOnly={true} />
                            ) : (
                                <Typography className={styles.query}>{currentResponse.RequestBody}</Typography>
                            )}

                            <StatusDropDown status={status} onChange={(value) => setStatus(value)} />
                        </div>
                        <DTextArea
                            label={getTextTranslated('Write your Response', 'support')}
                            className={styles.responseTextArea}
                            value={response}
                            onChange={(e) => setResponse(e.target.value)}
                            error={!response && attachments.length > 0}
                            helperText={attachments.length > 0 && !response && getTextTranslated('This field is required', NAMESPACE.COMMON)}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} md={12} gap={1} display="flex" justifyContent="right" alignItems="center">
                        {fileDetails.length > 0 &&
                            fileDetails.map((file) => (
                                <Chip
                                    key={file.attachmentId}
                                    variant="outlined"
                                    label={file.fileName}
                                    onDelete={() => deleteAttachmentHandler(file.attachmentId)}
                                />
                            ))}
                        {showAttachmentFeature() === true && fetchFileLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Button component="label" disabled={fileURL.length > 0}>
                                <DriscollsToolTip
                                    headingTitle={getTextTranslated('Accepted File Format', NAMESPACE.COMMON)}
                                    description="*.pdf"
                                    arrow={false}
                                    placement="bottom"
                                    className={null}
                                >
                                    <AttachFile />
                                </DriscollsToolTip>
                                <input hidden onChange={handleCapture} type="file" accept="application/pdf" />
                            </Button>
                        )}
                        <DriscollsLoadingButton
                            variant={ButtonVariant.SECONDARY}
                            onClick={goBackHandler}
                            label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                        />
                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            loading={responseLoading}
                            disabled={!response}
                            onClick={sendResponse}
                            label={getTextTranslated('Send', NAMESPACE.COMMON)}
                        />
                    </Grid>
                    {loading && <DriscollsSpinner text={getTextTranslated('Loading Form Data', NAMESPACE.REPORT)} />}
                </Box>
            </Container>
        </DrcContainer>
    );
}

export default withOktaAuth(ResponseForm);
