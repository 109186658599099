import { DriscollsRichText, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Container, TextField } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import AnnouncementCreateFooter from 'components/announcement/AnnouncementCreateFooter';
import AnnouncementRecipients from 'components/announcement/AnnouncementRecipients';
import AnnouncementValidation from 'components/announcement/AnnouncementValidation';
import { FOLDER_NAMES } from 'constants/foldernames';
import ApplicationContext from 'context/ApplicationContext';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useDeleteAttachment from 'hooks/useDeleteAttachment';
import useGetAllRanches from 'hooks/useGetAllRanches';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import useSendAnnouncement from 'hooks/useSendAnnouncement';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import { convertDateToIso } from '../../utils/helper';

const CreateAnnouncement = (props) => {
    useCheckMasterData();
    useMapSuperAdminData();
    const [richText, setRichText] = useState('');
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedRanches, setSelectedRanches] = useState([]);
    const [expirationDate, setExpirationDate] = useState(null);
    const [announcementHeader, setAnnouncementHeader] = useState('');
    const [fileDetails, setFileDetails] = useState([]);
    const currentFile = useRef();
    const [attachments, setAttachments] = useState([]);
    const navigate = useNavigate();
    const [formTouched, setFormTouched] = useState(false);
    const { sendAnnouncementHandler, loading } = useSendAnnouncement(props.oktaAuth);
    const { fetchSignedUrl, loading: fetchFileLoading } = useGetSignedUrl(props.oktaAuth);
    const { deleteAttachment, errorDeleteAttachment, loading: deleteAttachmentLoading } = useDeleteAttachment(props.oktaAuth);
    const ranches = useGetAllRanches();
    const { districtOptions: districts, ranches: companies, setShowToast } = useContext(ApplicationContext);

    const { getTextTranslated } = useLang();

    useEffect(() => {
        if (
            !formTouched &&
            (announcementHeader ||
                selectedDistricts.length > 0 ||
                selectedRanches.length > 0 ||
                selectedCompanies.length > 0 ||
                expirationDate ||
                richText)
        ) {
            setFormTouched(true);
        }
    }, [announcementHeader, selectedDistricts, selectedRanches, selectedCompanies, expirationDate, richText, formTouched]);

    const sendAnnouncement = async () => {
        const districtsArray = selectedDistricts.map((obj) => obj.DistrictCode).join(', ');
        const companiesArray = selectedCompanies.map((obj) => obj.CompanyNbr).join(', ');
        const ranchesArray = selectedRanches.map((obj) => obj.Code).join(', ');
        const sendAnnouncementPayload = {
            Districts: districtsArray.length > 0 ? districtsArray : null,
            Companies: companiesArray.length > 0 ? companiesArray : null,
            Ranches: ranchesArray.length > 0 ? ranchesArray : null,
            RecipientType: 'GROWER',
            TextHeader: announcementHeader,
            TextBody: richText,
            AttachementLink: attachments.length > 0 ? attachments.map((itm) => itm).join(',') : '',
            FileType: fileDetails.length > 0 ? fileDetails[0].fileFormat : '',
            FileName: fileDetails.length > 0 ? fileDetails[0].fileName : '',
            FileKey: fileDetails.length > 0 ? fileDetails[0].attachmentId : '',
            ExpirationDate: convertDateToIso(expirationDate)
        };
        sendAnnouncementHandler(sendAnnouncementPayload);
    };

    const deleteAttachmentHandler = (attachmentId) => {
        deleteAttachment(attachmentId);
        if (!errorDeleteAttachment) setFileDetails(fileDetails.filter((file) => file.attachmentId !== attachmentId));
    };

    const handleChange = (event, name) => {
        if (event) {
            switch (name) {
                case 'District': {
                    const isValueAdded = selectedDistricts.find((item) => item.label === event.label);
                    isValueAdded
                        ? setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Already added.' })
                        : setSelectedDistricts([...selectedDistricts, event]);
                    break;
                }
                case 'Company': {
                    const isValueAdded = selectedCompanies.find((item) => item.label === event.label);
                    isValueAdded
                        ? setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Already added.' })
                        : setSelectedCompanies([...selectedCompanies, event]);
                    break;
                }
                case 'Ranch': {
                    const isValueAdded = selectedRanches.find((item) => item.label === event.label);
                    isValueAdded
                        ? setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Already added.' })
                        : setSelectedRanches([...selectedRanches, event]);
                    break;
                }
                default:
                    setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            }
        }
    };

    const handleDelete = (event, name) => {
        switch (name) {
            case 'District':
                setSelectedDistricts([...selectedDistricts.filter((itm) => itm.label !== event.label)]);
                break;
            case 'Company':
                setSelectedCompanies([...selectedCompanies.filter((itm) => itm.label !== event.label)]);
                break;
            case 'Ranch':
                setSelectedRanches([...selectedRanches.filter((itm) => itm.label !== event.label)]);
                break;
            default:
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
        }
    };

    const completeUpload = (uploadId) => {
        //This completes the upload process
        setAttachments([...attachments, uploadId + '.' + currentFile.current.fileFormat]);
        currentFile.current = { ...currentFile.current, attachmentId: uploadId };
        setFileDetails([...fileDetails, { ...currentFile.current, attachmentId: uploadId }]);
    };

    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const file = target.files[0];

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            console.log(e.target.result);
            const fileFormat = file.name.split('.').pop();
            currentFile.current = { fileName: file.name, fileFormat };
            fetchSignedUrl(file, file.type, file.name, FOLDER_NAMES.announcement, completeUpload);
        };
    };

    const formIsValid = () => {
        return (
            announcementHeader &&
            (selectedDistricts.length > 0 || selectedRanches.length > 0 || selectedCompanies.length > 0) &&
            expirationDate &&
            richText
        );
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.ANNOUNCEMENT}`);
    };

    return (
        <DrcContainer heading={getTextTranslated('Create Announcement', NAMESPACE.ANNOUNCEMENT)} goBackHandler={goBackHandler}>
            <Container>
                <TextField
                    label={getTextTranslated('Announcement Header', NAMESPACE.ANNOUNCEMENT)}
                    onChange={(e) => setAnnouncementHeader(e.target.value)}
                    value={announcementHeader}
                    sx={{ mb: 2 }}
                    fullWidth
                />

                <AnnouncementRecipients
                    selectedRanches={selectedRanches}
                    selectedDistricts={selectedDistricts}
                    selectedCompanies={selectedCompanies}
                    districts={districts}
                    ranches={ranches}
                    companies={companies}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                />

                <DriscollsRichText label={`${getTextTranslated('Start typing', NAMESPACE.COMMON)}...`} getJsonValue={(value) => setRichText(value)} />

                <AnnouncementCreateFooter
                    expirationDate={expirationDate}
                    setExpirationDate={setExpirationDate}
                    fileDetails={fileDetails}
                    deleteAttachment={deleteAttachmentHandler}
                    deleteAttachmentLoading={deleteAttachmentLoading}
                    handleCapture={handleCapture}
                    fetchFileLoading={fetchFileLoading}
                    sendAnnouncement={sendAnnouncement}
                    loading={loading}
                    disabledSendAnnouncement={formIsValid()}
                />

                {formTouched && !formIsValid() && (
                    <AnnouncementValidation
                        announcementHeader={announcementHeader}
                        expirationDate={expirationDate}
                        recipients={selectedDistricts.length === 0 && selectedRanches.length === 0 && selectedCompanies.length === 0}
                        richText={richText}
                    />
                )}
            </Container>
        </DrcContainer>
    );
};

export default withOktaAuth(CreateAnnouncement);
