import {
    PERSONA_ROUTES,
    PersonaDetailsContext,
    PersonaInfoPage,
    TPersonaDetailsType,
    TStepperPath,
    Toaster,
    syncTranslationUserManagementModule
} from '@driscollsinc/users-management-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import useLang from 'hooks/useLang';
import DrcContainer from 'layouts/dashboard/DrcContainer';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import AddPersona from './AddPersona';
import FeaturePersona from './FeaturePersona';
import ManagePersonas from './ManagePersonas';
import PersonaManagement from './PersonaManagement';
import styles from './PersonaManagement.module.scss';

const PersonaManagementRoutes = () => {
    const initialData = {
        type: { web: true, mobile: false, internal: true, ext: false },
        notifications: { whatsapp: false, tableauUser: false, email: false }
    } as TPersonaDetailsType;
    const [personaDetails, setPersonaDetails] = useState(initialData);
    const { getCurrentLanguageSelected } = useLang();
    const currentLang = getCurrentLanguageSelected();
    useEffect(() => {
        syncTranslationUserManagementModule(currentLang);
    }, [currentLang]);

    const navigate = useNavigate();

    const [stepperPath, setStepperPath] = useState({} as TStepperPath);
    const [activeStep, setActiveStep] = useState(0);

    const valueMemo = useMemo(
        () => ({ personaDetails, setPersonaDetails, activeStep, setActiveStep, stepperPath, setStepperPath }),
        [personaDetails, activeStep, stepperPath]
    );

    return (
        <PersonaBaseProtect
            personasAllowed={[Persona.SUPER_ADMIN, Persona.IT_ADMIN]}
            featureName={FEATURES.PERSONA_MANAGEMENT}
            access="Write"
            forbiddenComponent={<DrcPageNotFound />}
        >
            <PersonaDetailsContext.Provider value={valueMemo}>
                <DrcContainer heading="Persona Management" goBackHandler={() => navigate(`${ROUTES.ROOT}${ROUTES.PERSONA_MANAGEMENT}`)}>
                    <div className={styles.personaModuleContainer}>
                        <Toaster>
                            <Routes>
                                <Route path="" element={<PersonaManagement />} />
                                <Route path={`${PERSONA_ROUTES.CREATE}/:id?`} element={<AddPersona />}>
                                    <Route path="" element={<Navigate to={PERSONA_ROUTES.INFO} />} />
                                    <Route path={PERSONA_ROUTES.INFO} element={<PersonaInfoPage />} />
                                    <Route path={PERSONA_ROUTES.MANAGE} element={<ManagePersonas />} />
                                    <Route path={PERSONA_ROUTES.FEATURES} element={<FeaturePersona />} />
                                </Route>
                            </Routes>
                        </Toaster>
                    </div>
                </DrcContainer>
            </PersonaDetailsContext.Provider>
        </PersonaBaseProtect>
    );
};

export default withOktaAuth(PersonaManagementRoutes);
