import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Print from './Print';

const PrintRoutes = () => {
    return (
        <PersonaBaseProtect
            personasAllowed={[Persona.SUPER_ADMIN, Persona.PTI_APP, Persona.PTI_USER]}
            featureName={FEATURES.PTI}
            access="Read"
            forbiddenComponent={<DrcPageNotFound />}
        >
            <Routes>
                <Route path="" element={<Print />} />
                <Route path={ROUTES.PTIPARAMS} element={<Print />} />
            </Routes>
        </PersonaBaseProtect>
    );
};

export default PrintRoutes;
