import { i18next } from '@driscollsinc/style-guide-web2.0';
import AppProvider from 'context/AppProvider';
import AppProviderV2 from 'context/AppProviderV2';
import useLang, { Lang } from 'hooks/useLang';
import React, { useEffect } from 'react';
import App from './App';
import './i18n';
import { defaultLanguageHandler } from './utils/languageHelper';
const Application = () => {
    const { changeLanguage, getCurrentLanguageSelected } = useLang();
    useEffect(() => {
        // init language
        const defaultLanguageCode = defaultLanguageHandler() as Lang;
        changeLanguage(defaultLanguageCode);
    }, []);
    useEffect(() => {
        i18next.changeLanguage(getCurrentLanguageSelected());
    }, [getCurrentLanguageSelected]);
    return (
        <AppProviderV2>
            <AppProvider>
                <App />
            </AppProvider>
        </AppProviderV2>
    );
};
export default Application;
