import { ButtonVariant, DriscollsHContainer, DriscollsLoadingButton, DriscollsRichText } from '@driscollsinc/style-guide-web2.0';
import { AttachFile } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { FOLDER_NAMES } from 'constants/foldernames';
import { ROUTES } from 'constants/routes';
import { PERSONAID } from 'data/config/persona';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useDeleteAttachment from 'hooks/useDeleteAttachment';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useRaiseRequest from 'hooks/useRaiseRequest';
import { NAMESPACE } from 'i18n';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import ApplicationContext from '../../context/ApplicationContext';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import DriscollsToolTip from '../../ui/DriscollsToolTip/DriscollsToolTip';
import styles from './QueryForm.module.scss';

function QueryForm(props) {
    useCheckMasterData();
    const { userPersona, companies, districtOptions } = useContext(ApplicationContext);
    const { getTextTranslated, getCurrentLanguageSelected } = useLang();
    const priority = [
        { label: 'High', value: 1 },
        { label: 'Medium', value: 2 },
        { label: 'Low', value: 3 }
    ];
    const navigate = useNavigate();
    const userLang = getCurrentLanguageSelected();
    const [requestSubject, setRequestSubject] = useState();
    const [requestBody, setRequestBody] = useState('');
    const [ticketPriority, setTicketPriority] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState({});
    const [fileDetails, setFileDetails] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const currentFile = useRef();
    const { fetchSignedUrl, loading: fetchFileLoading } = useGetSignedUrl(props.oktaAuth);
    const { deleteAttachment, errorDeleteAttachment } = useDeleteAttachment(props.oktaAuth);
    const { raiseRequestHandler, loading } = useRaiseRequest(props.oktaAuth);
    const districts = [PERSONAID.GROWERUSER, PERSONAID.QUALITYVIEWER].includes(userPersona) ? districtOptions : companies;

    const raiseRequest = () => {
        const requestPayload = {
            RequestId: null,
            DistrictId: selectedDistrict,
            CreatedAt: new Date().toISOString(),
            Status: 'OPEN',
            RequestBody: requestBody,
            Priority: ticketPriority,
            RequestHeader: requestSubject,
            Resolution: '',
            UserLanguage: userLang,
            AttachementURL: attachments.length > 0 ? attachments.map((itm) => itm).join(',') : '',
            FileType: fileDetails.length > 0 ? fileDetails[0].fileFormat : ''
        };
        raiseRequestHandler(requestPayload);
    };

    const deleteAttachmentHandler = (attachmentId) => {
        deleteAttachment(attachmentId);
        if (!errorDeleteAttachment) setFileDetails(fileDetails.filter((file) => file.attachmentId !== attachmentId));
    };

    const completeUpload = (uploadId) => {
        //This completes the upload process
        setAttachments([...attachments, uploadId + '.' + currentFile.current.fileFormat]);
        currentFile.current = { ...currentFile.current, attachmentId: uploadId };
        setFileDetails([...fileDetails, { ...currentFile.current, attachmentId: uploadId }]);
    };

    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const file = target.files[0];

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            console.log(e.target.result);
            const fileFormat = file.name.split('.').pop();
            currentFile.current = { fileName: file.name, fileFormat };
            fetchSignedUrl(file, file.type, file.name, FOLDER_NAMES.support, completeUpload);
        };
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.SUPPORT}`);
    };

    return (
        <DrcContainer heading="Raise Query" goBackHandler={goBackHandler}>
            <Container>
                <Box sx={{ flexGrow: 1, marginTop: 5 }}>
                    <Grid spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                        <TextField
                            id="outlined-multiline-static"
                            label={getTextTranslated('Subject', NAMESPACE.COMMON)}
                            sx={{ width: '100%' }}
                            value={requestSubject}
                            onChange={(e) => setRequestSubject(e.target.value)}
                        />
                    </Grid>
                    <FormControl sx={{ marginTop: '20px', width: '100%' }}>
                        <InputLabel id="query-label">{getTextTranslated('Select District', NAMESPACE.SUPPORT)}</InputLabel>
                        <Select
                            label={getTextTranslated('Select Priority', NAMESPACE.SUPPORT)}
                            labelId="query-label"
                            value={selectedDistrict.DistrictId}
                            onChange={(e) => {
                                setSelectedDistrict(e.target.value);
                            }}
                        >
                            {(districts || []).map((item, index) => {
                                return (
                                    <MenuItem value={item.DistrictId} key={index}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Grid sx={{ marginTop: 3 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                        <DriscollsRichText
                            label={getTextTranslated('Write your Query', NAMESPACE.COMMON)}
                            getJsonValue={(value) => setRequestBody(value)}
                            onChange={() => null}
                            editorState={null}
                        />
                    </Grid>
                    <FormControl sx={{ marginTop: '20px', width: '100%' }}>
                        <InputLabel id="query-label">{getTextTranslated('Select Priority', NAMESPACE.SUPPORT)}</InputLabel>
                        <Select
                            label={getTextTranslated('Select Priority', NAMESPACE.SUPPORT)}
                            labelId="query-label"
                            value={ticketPriority}
                            onChange={(e) => {
                                setTicketPriority(e.target.value);
                            }}
                        >
                            {priority.map((item, index) => {
                                return (
                                    <MenuItem value={item.value} key={index}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <DriscollsHContainer wrap className={styles.buttonFooter}>
                        {fileDetails.length > 0 &&
                            fileDetails.map((file) => (
                                <Chip
                                    key={file.attachmentId}
                                    variant="outlined"
                                    label={file.fileName}
                                    onDelete={() => deleteAttachmentHandler(file.attachmentId)}
                                />
                            ))}
                        {showAttachmentFeature() === true && fetchFileLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Button component="label">
                                <DriscollsToolTip
                                    headingTitle={getTextTranslated('Accepted File Format', NAMESPACE.COMMON)}
                                    description="*.jpg, *.png, *.pdf"
                                    arrow={false}
                                    placement="bottom"
                                    className={null}
                                >
                                    <AttachFile />
                                </DriscollsToolTip>
                                <input hidden onChange={handleCapture} type="file" accept="image/png, application/pdf, image/jpg" />
                            </Button>
                        )}
                        <DriscollsLoadingButton
                            variant={ButtonVariant.SECONDARY}
                            onClick={goBackHandler}
                            label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                        />

                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            loading={loading}
                            disabled={!ticketPriority || !requestSubject || !requestBody}
                            onClick={raiseRequest}
                            label={getTextTranslated('Send', NAMESPACE.COMMON)}
                        />
                    </DriscollsHContainer>
                </Box>
            </Container>
        </DrcContainer>
    );
}

export default withOktaAuth(QueryForm);
