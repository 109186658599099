import axios from 'axios';
import { CANADA_MANUAL_TAX_REPORT_ID, GROWER_SETTLEMENT_REPORT_ID, SUPPLEMENTAL_STATEMENT_REPORT_ID } from 'hooks/useGetReports';
import { ROUTES } from '../constants/routes';
import { urls } from '../constants/urls';
class APIEndPoints {
    static BI_BASE = window.config.BI_REPORT_ADDRESS;
    static GLUE_JOB = window.config.GLUE_JOB_BASE_ADDRESS;
    static API_BASE_GROWER_ADDRESS = window.config.API_BASE_GROWER_ADDRESS;
    static BUS_BASE = window.config.BUS_URL;
    static AWS_BASE = window.config.AWSBASEURL;
    static AWS_API_BASE = window.config.AWS_API_BASE_URL;
    static AWS_API = {
        userManagement: window.config.AWS_API.userManagement
    };
    static BASE = () => {
        const provider = localStorage.getItem('authenticationProvider');
        return provider === 'cognito' ? window.config.API_BASE_ADDRESS.split('api/v1/').join('api/ext/') : window.config.API_BASE_ADDRESS;
    };

    static AWS_USER_MANAGEMENT_API = () => {
        return `${this.AWS_API.userManagement}`;
    };

    static GET_PERSONA_ID = (oktaId) => {
        return `${this.BASE()}${urls.USERS}/${oktaId}/${urls.PERSONA_ID}`;
    };

    static MANAGE_USER_GROWER = () => {
        return `${this.BASE()}${urls.USERS}/manage`;
    };

    static GET_USER_STATUS = (oktaUserId) => {
        return `${this.BASE()}${urls.USERS}/${oktaUserId}/status`;
    };

    static GET_USER_FEATURES = (useroktaid) => {
        return `${this.BASE()}userfeatures?useroktaid=${useroktaid}`;
    };

    static GET_USER_BY_GROUP = (data) => {
        return `${this.API_BASE_GROWER_ADDRESS}/${urls.USERS}/groups?groupid=${data}`;
    };

    static GET_USER_PROFILE = (email) => {
        return `${this.BASE()}${urls.USERS}/${urls.PROFILE}?login=${email}`;
    };

    static GET_GROWER_USER_PROFILE = (login) => {
        // this is temp to fetch user details from grower api
        return `${this.BASE()}${urls.USERS}/${urls.PROFILE}?login=${login}`;
    };

    static POST_USER_PROFILE = () => `${this.BASE()}${urls.USERS}/pii`;

    static GET_DISTRICTS = (personaid, useroktaid) => {
        return `${this.BASE()}${urls.USERS}/${urls.DISTRICTS}?${urls.PERSONA_ID}=${personaid}&${urls.USER_OKTA_ID}=${useroktaid}`;
    };

    static GET_RANCHES_FOR_PERSONA = (personaid, useroktaid) => {
        return `${this.BASE()}${urls.USERS}/${urls.RANCHES}?${urls.PERSONA_ID}=${personaid}&${urls.USER_OKTA_ID}=${useroktaid}`;
    };

    static GET_SUPERADMIN_RANCHES = (producingAreaCode, commodityCode, growerNbr) => {
        const growerString = growerNbr !== undefined && growerNbr !== '' ? `&${urls.GROWERNBR}=${growerNbr}` : '';
        const producingAreaString =
            producingAreaCode !== undefined && producingAreaCode !== '' ? `&${urls.PRODUCING_AREA_CODE}=${producingAreaCode}` : '';
        const commodityCodeString = commodityCode !== undefined && commodityCode !== '' ? `&${urls.COMMODITY_CODE}=${commodityCode}` : '';
        return `${this.BASE()}${urls.SUPER_ADMIN}/${urls.RANCHES}?status=open${`${growerString}${producingAreaString}${commodityCodeString}`}`;
    };

    static GET_SUPERADMIN_DISTRICTS = (y) => {
        return `${this.BASE()}${urls.SUPER_ADMIN}/${urls.DISTRICTS}`;
    };

    static GET_SUPERADMIN_COMPANIES = (y) => {
        return `${this.BASE()}${urls.SUPER_ADMIN}/${urls.COMPANIES}?status=open`;
    };

    static GET_COMPANIES_FOR_PERSONA = (personaid, useroktaid) => {
        return `${this.BASE()}${urls.USERS}/${urls.COMPANIES}?${urls.PERSONA_ID}=${personaid}&${urls.USER_OKTA_ID}=${useroktaid}`;
    };

    static GET_BASIC_INFORMATION = (country = 0) => {
        return `${this.BASE()}${urls.USERS}/${urls.BASIC_INFORMATION}?${urls.COUNTRY}=${country}`;
    };

    static POST_CREATE_OKTA_USER = (isOktaUser) => {
        return isOktaUser ? `${this.BASE()}/${urls.USERS}/okta/user` : `${this.AWS_BASE}/${urls.SIGN_UP}/${urls.ONE_DRISCOLLS}/`;
    };

    static POST_OKTA_MAKE_ASSIGNMENT = (useroktaid, isEdit = false) => {
        return isEdit ? `${this.BASE()}${urls.USERS}/${useroktaid}?edit=true` : `${this.BASE()}${urls.USERS}/${useroktaid}?edit=false`;
    };

    static POST_OKTA_ADD_TO_GROUP = (useroktaid) => {
        return `${this.BASE()}${urls.USERS}/${urls.OKTA_GROUP}/`;
    };

    static GET_OKTA_USERS = (filter) => {
        return `${this.BASE()}${urls.USERS}/${urls.USER_LIST}?limit=20&filter=${filter}`;
    };

    static ACTIVATE_USER = (userOktaId, isOktaUser) => {
        return isOktaUser
            ? `${this.BASE()}${urls.USERS}/${urls.ACTIVATE_USER}?${urls.USER_OKTA_ID}=${userOktaId}`
            : `${this.AWS_BASE}/${urls.SIGN_UP}/${urls.ONE_DRISCOLLS}/`;
    };

    static DEACTIVATE_USER = (userOktaId, isOktaUser) => {
        return isOktaUser ? `${this.BASE()}${urls.USERS}/${urls.DEACTIVATE_USER}` : `${this.AWS_BASE}/${urls.DISABLE_USER}/${urls.ONE_DRISCOLLS}/`;
    };

    static DELETE_COGNITOUSER = () => {
        return `${this.AWS_BASE}/${urls.DELETE_USER}/${urls.ONE_DRISCOLLS}/`;
    };

    static REMOVE_PERSONA = () => {
        return `${this.BASE()}${urls.USERS}/${urls.PERSONA}/${urls.UPDATE}`;
    };

    static REMOVE_USER_FROM_OKTA_GROUP = (userOktaId, personaid) => {
        return `${this.BASE()}${urls.USERS}/${urls.DELETE_USER_FROM_GROUP}?${urls.USER_OKTA_ID}=${userOktaId}&${urls.PERSONA_ID}=${personaid}`;
    };

    static MAKE_DEFAULT_PERSONA = () => {
        return `${this.BASE()}${urls.USERS}/${urls.PERSONA}`;
    };

    static GET_PERSONA_DETAILS = () => {
        return `${this.BASE()}${urls.USERS}/${urls.PERSONA}/details`;
    };

    static GET_ASSIGNED_REPORTS = (userOktaId, lang = 'en') => {
        return `${this.BASE()}${urls.USERS}/${urls.REPORTS}/${userOktaId}?lang=${lang}`;
    };

    static GET_REPORTS_BY_CATEGORY = () => {
        return `${this.BASE()}${urls.REPORT}/${urls.CATEGORY}`;
    };

    static POST_REPORT_ASSIGN = () => {
        return `${this.BASE()}${urls.USERS}/${urls.REPORTS}`;
    };

    static GET_SUPPORT = () => {
        return `${this.BASE()}${urls.SUPPORT}`;
    };

    static GET_REPORTS_LIST = (lang = 'en') => {
        return `${this.BASE()}${urls.REPORTS}/list?lang=${lang}`;
    };

    static GET_ANNOUNCEMENTS = () => `${this.BASE()}${urls.ANNOUNCEMENT}/retrieve`;

    static GET_SENT_ANNOUNCEMENTS = (authId, announcementId) =>
        `${this.BASE()}${urls.ANNOUNCEMENT}/sent?senderauthid=${authId}${announcementId ? `&announcementid=${announcementId}` : ''}`;

    static POST_ANNOUNCEMENTS = () => `${this.BASE()}${urls.ANNOUNCEMENT}/store`;

    static READ_ANNOUNCEMENTS = () => `${this.BASE()}${urls.ANNOUNCEMENT}/read`;

    static DELETE_ANNOUNCEMENT = () => `${this.BASE()}${urls.ANNOUNCEMENT}/delete`;

    static POST_SUPPORT_RESPONSE = () => {
        return `${this.BASE()}${ROUTES.RESPONSE_PATH}`;
    };
    static POST_SUPPORT_REQUEST = () => {
        return `${this.BASE()}${urls.REQUEST}`;
    };
    //Tableau
    static GET_TABLEAU_EMBED_TOKEN = (userId) => {
        return `${this.BASE()}${urls.TABLEAU}/token?userId=${userId}`;
    };

    //AwsEndpoints
    static GET_SIGNED_UPLOAD_URL = (fileName, mimeType, folderName, isOktaUser) => {
        return `${this.BUS_BASE}${!isOktaUser ? urls.COGNITO : ''}${urls.FILE}/${
            urls.UPLOAD
        }/?name=${fileName}&filetype=${mimeType}&folderName=${folderName}`;
    };

    static GET_ATTACHMENT_URL = (attachmentId, isOktaUser) => {
        return `${this.BUS_BASE}${!isOktaUser ? urls.COGNITO : ''}${urls.FILE}/${urls.DOWNLOAD}/${attachmentId}`;
    };

    static DELETE_ATTACHMENT = (attachmentId) => {
        //http method DELETE
        //response {"msg":"Successfully deleted entry 9bdb8885-90d5-4410-96de-fa1ae911ed4d"}
        return `${this.BUS_BASE}${urls.FILE}/${attachmentId}`;
    };

    /**
     *
     * @param {*} authId it could be okta token or cognito token.
     * If external user we expect to be cognito token otherwise okta token
     * @returns
     */
    static GET_CONTACTS = (authId) => {
        return `${this.BASE()}users/contact/details?authid=${authId}`;
    };

    /**
     *
     * @param {string} authId it could be okta token or cognito token.
     * @param {number} personaid this is the logged in users persona id. Refer PERSONAID
     * If external user we expect to be cognito token otherwise okta token
     * @returns
     */
    static GET_NOTIFIED_ASSIGNMENTS = (personaid, authId) => {
        return `${this.BASE()}dashboard/assignments?personaid=${personaid}&authid=${authId}`;
    };

    /**
     *
     * @param {string} authId it could be okta token or cognito token.
     * @param {number} personaid this is the logged in users persona id. Refer PERSONAID
     * If external user we expect to be cognito token otherwise okta token
     * @returns
     */
    static GET_ENTITY_ASSIGNED_USERS = (personaid, requestorId, entity) => {
        return `${this.BASE()}${urls.DASHBOARD}/${urls.USER_LIST}?${urls.PERSONA_ID}=${personaid}&${urls.REQUESTOR_ID}=${requestorId}&${
            urls.ENTITY
        }=${entity}`;
    };

    /**
     *
     * @param {string} authId it could be okta token or cognito token.
     * @param {number} personaid this is the logged in users persona id. Refer PERSONAID
     * If external user we expect to be cognito token otherwise okta token
     * @returns
     */
    static POST_ASSIGN_ENTITY_TO_USERS = (selectedUsers, entityName, selectedEntity) => {
        return `${this.BASE()}${urls.USERS}/${urls.ASSIGNMENT}/${entityName?.toLowerCase()}`;
    };

    //event apis
    static POST_CREATEEVENT = () => `${this.BASE()}${urls.EVENTS}`;
    static GET_EVENT_DETAILS = (eventid, userOktaId) => `${this.BASE()}${urls.EVENTS}?useroktaid=${userOktaId}&eventid=${eventid}`;
    static GET_RECENTEVENTS = (count, userOktaId) => `${this.BASE()}${urls.EVENTS}?useroktaid=${userOktaId}&latestevents=${count}`;
    static GET_EVENTS = (startDate, endDate, userOktaId) => {
        return startDate && endDate
            ? `${this.BASE()}${urls.EVENTS}?useroktaid=${userOktaId}&startdate=${startDate}&enddate=${endDate}`
            : `${this.BASE()}${urls.EVENTS}?useroktaid=${userOktaId}`;
    };
    static GET_NOTIFICATIONS = (authId, personaId) => {
        return `${this.BASE()}dashboard/details?authid=${authId}&personaid=${personaId}`;
    };
    static REMOVE_EVENT = () => {
        return `${this.BASE()}events`;
    };

    //QA Inspection Report end points
    static GET_QA_REPORTS = (useroktaid, offset, limit, query, reporttype) =>
        `${this.BASE()}${urls.QA_REPORTS}?${
            urls.USER_OKTA_ID
        }=${useroktaid}&reporttype=${reporttype}&search=${query}&offset=${offset}&limit=${limit}`;

    static GET_GROWER_SETTLEMENT_REPORTS = (userOktaId, offset, limit, filters = '') =>
        `${this.BASE()}${urls.REPORT}?${urls.USER_OKTA_ID}=${userOktaId}&userreportid=${GROWER_SETTLEMENT_REPORT_ID}&limit=${limit}&offset=${offset}${
            filters ? `&${filters}` : ''
        }`;

    static POST_SIGN_UP_COGNITO_USER = `${this.AWS_BASE}/${urls.SIGN_UP}/`;
    static GET_COGNITO_USERS = `${this.AWS_BASE}/${urls.GET_USERS_LIST}/`;
    static GET_COGNITO_USER_PROFILE = `${this.AWS_BASE}/${urls.GET_USER}/${urls.ONE_DRISCOLLS}/`;
    static POST_DISABLE_COGNITO_USER = `${this.AWS_BASE}/${urls.DISABLE_USER}/`;
    static POST_ENABLED_COGNITO_USER = `${this.AWS_BASE}/${urls.ENABLE_USER}/`;
    static POST_DELETE_COGNITO_USER = `${this.AWS_BASE}/${urls.DELETE_USER}/`;
    static GET_COGNITO_USER_DETAILS = `${this.AWS_BASE}/${urls.GET_USER_DETAILS}/`;
    static SEARCH_COGNITO_USER = `${this.AWS_BASE}/${urls.SEARCH_COGNITO_USER}/`;
    static PUT_UPDATE_COGNITO_USER = `${this.AWS_BASE}/${urls.UPDATE_USER}/${urls.ONE_DRISCOLLS}/`;

    static uploadToS3 = async (presignedUrl, fileType, file, folderName, completeUploadCallBack) => {
        const config = {
            method: 'put',
            url: presignedUrl,
            headers: {
                'Content-Type': fileType,
                'Content-Length': file.size
            },
            data: file
        };
        try {
            const response = await axios(config);
            const uploadId = presignedUrl.substring(presignedUrl.indexOf(folderName) + folderName.length + 1, presignedUrl.indexOf('?'));
            completeUploadCallBack(uploadId, file);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    //Library End Points
    static GET_SITE_LISTING = () => {
        return `${this.BASE()}${urls.SHAREPOINT}/${urls.SITES}`;
    };

    static GET_SITE_ITEMS = (listId, perPage, skipToken) => {
        return `${this.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.ITEMS}?${urls.LISTID}=${listId}&${urls.TOP}=${perPage}&${
            urls.SKIP_TOKEN
        }=${skipToken}`;
    };

    // Google Analytics
    static GET_REPORT = () => {
        return `${this.AWS_BASE}/${urls.GOOGLE_ANALYTICS_REPORT}`;
    };

    //Sharepoint
    static GET_SHAREPOINT_SEARCH = (query) => {
        return `${this.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.SHAREPOINT_SEARCH}?${urls.SHAREPOINT_SEARCH}=${query}`;
    };

    static GET_SHAREPOINT_DOWNLOAD_URL = (itemId, listId) => {
        return `${this.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.ITEMS}/${urls.SHAREPOINT_DOWNLOAD}?${urls.LISTID}=${listId}&${
            urls.ITEMID
        }=${itemId}`;
    };

    static GET_LATEST_ITEMS = () => {
        return `${this.BASE()}${urls.SHAREPOINT}/${urls.SITES}/${urls.NOTIFICATION}`;
    };

    static GET_WEATHER_REPORT = (frequency) => {
        return `${this.BASE()}forecasts/${frequency}`;
    };

    //ptic
    static GET_PRODUCING_AREAS = (personaId, useroktaid) => {
        return `${this.BASE()}${urls.PTIC}/${urls.PRODUCING_AREAS}?${urls.PERSONA_ID}=${personaId}&${urls.USER_OKTA_ID}=${useroktaid}`;
    };

    static GET_WAREHOUSES = (producingAreaCode, ranchNumber) => {
        return `${this.BASE()}${urls.PRODUCING_AREA}/${urls.WAREHOUSES}?${urls.PRODUCING_AREA_CODE}=${producingAreaCode}&${
            urls.RANCHNBR
        }=${ranchNumber}`;
    };

    static GET_BERRY_TYPES = (producingAreaCode, useroktaid, personaId) => {
        return `${this.BASE()}${urls.PTIC}/${urls.BERRYTYPE}?${urls.PRODUCING_AREA_CODE}=${producingAreaCode}&${urls.USER_OKTA_ID}=${useroktaid}&${
            urls.PERSONA_ID
        }=${personaId}`;
    };

    static GET_GROWERS = (useroktaid, personaId, commodityCode, producingArea) => {
        const commodityString = commodityCode !== undefined && commodityCode !== '' ? `&${urls.COMMODITY_CODE}=${commodityCode}` : '';
        return `${this.BASE()}${urls.PTIC}/${urls.GROWERS}?${urls.PERSONA_ID}=${personaId}&${urls.USER_OKTA_ID}=${useroktaid}${commodityString}&${
            urls.PRODUCING_AREA_CODE
        }=${producingArea}`;
    };

    //Persona 8 or other than super admin (1)
    static GET_PTIC_RANCHES = (useroktaid, producingAreaCode, commodityCode, growerNbr) => {
        const growerString = growerNbr !== undefined && growerNbr !== '' ? `&${urls.GROWERNBR}=${growerNbr}` : '';
        const commodityString = commodityCode !== undefined && commodityCode !== '' ? `&${urls.COMMODITY_CODE}=${commodityCode}` : '';
        return `${this.BASE()}${urls.PTIC}/${urls.RANCHES}?${urls.PRODUCING_AREA_CODE}=${producingAreaCode}&${
            urls.USER_OKTA_ID
        }=${useroktaid}${commodityString}${growerString}`;
    };

    static GET_HARVEST_ORDERS = (
        date,
        pageSize,
        producingAreaCode,
        warehouseNumber,
        commodityCode,
        growerNbr,
        ranchNbr,
        startRow,
        personaId,
        userOktaId,
        cognitoUser
    ) => {
        return `${this.BASE()}${urls.PTIC}/${urls.HARVEST_ORDERS}/${urls.LIST}?${urls.DATE}=${date}&${urls.PAGE_SIZE}=${pageSize}&${
            urls.PRODUCING_AREA_CODE
        }=${producingAreaCode}&${urls.WAREHOUSE_NUMBER}=${warehouseNumber}${growerNbr ? `&${urls.GROWERNBR}=${growerNbr}` : ''}${
            ranchNbr ? `&${urls.RANCHNBR}=${ranchNbr}` : ''
        }&${urls.COMMODITY_CODE}=${commodityCode}&${urls.START_ROW}=${startRow}&${urls.PERSONA_ID}=${personaId}&${urls.USER_OKTA_ID}=${userOktaId}&${
            urls.COGNITO_USER
        }=${cognitoUser}&webapp=true`; // webapp=true will always be there.
    };

    static GET_MANUAL_ITEMS = (company, berryType) => {
        return `${this.BASE()}${urls.ITEMS}?${urls.COMPANY}=${company}&${urls.SUBTYPE}=${berryType}`;
    };

    static GET_RANCH_DETAILS_BY_RANCH = (ranch) => `${this.BASE()}${urls.LISTRANCH}?${urls.RANCHNBR}=${ranch}`;

    static POST_MANUAL_HARVEST_ORDER = () => `${this.BASE()}${urls.PTIC}/${urls.MANUALHARVESTORDER}`;

    static POST_PRINT_HARVEST_ORDER = () => `${this.BASE()}${urls.PTIC}/${urls.PTIAUDIT}`;

    static GET_PTI_AUDIT_DETAILS = (ptiAuditId, personaId, cognitoUser, useroktaid, printCount, harvestOrder, itemNbr, packDate) => {
        const urlBase = `${this.BASE()}${urls.PTIC}/${urls.PTIAUDIT}?${urls.PTIAUDITID}=${ptiAuditId}`;
        if (personaId === 1) {
            return urlBase;
        }
        return `${urlBase}&${urls.PERSONA_ID}=${personaId}&${urls.COGNITO_USER}=${cognitoUser}&${urls.USER_OKTA_ID}=${useroktaid}${
            printCount ? `&${urls.PRINTCOUNT}=${printCount}` : ''
        }${harvestOrder ? `&${urls.HARVEST_ORDER}=${harvestOrder}` : ''}${itemNbr ? `&${urls.ITEMNBR}=${itemNbr}` : ''}${
            packDate ? `&${urls.PACKDATE}=${packDate}` : ''
        }`;
    };

    //Supplementary report
    static POST_VALIDATE_GROWERS = (payload) => {
        return `${this.BASE()}${urls.SUPPLEMENTAL_FILE}/${urls.VALID_GROWERS}`;
    };

    static POST_SUPPLEMENTARY_REPORT = (payload) => {
        return `${this.BASE()}${urls.SUPPLEMENTAL_FILE}/${urls.REPORT}`;
    };

    static GET_SUPPLEMENTARY_STATEMENT_REPORT = (userOktaId, offset, limit, filters = '') =>
        `${this.BASE()}${urls.REPORT}?${
            urls.USER_OKTA_ID
        }=${userOktaId}&userreportid=${SUPPLEMENTAL_STATEMENT_REPORT_ID}&limit=${limit}&offset=${offset}${filters ? `&${filters}` : ''}`;

    static GET_CANADA_MANUAL_TAX_REPORT = (userOktaId, offset, limit, filters = '') =>
        `${this.BASE()}${urls.REPORT}?${urls.USER_OKTA_ID}=${userOktaId}&userreportid=${CANADA_MANUAL_TAX_REPORT_ID}&limit=${limit}&offset=${offset}${
            filters ? `&${filters}` : ''
        }`;
}

export default APIEndPoints;
