import { DriscollsRichText } from '@driscollsinc/style-guide-web2.0';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import ViewAttachments from 'components/announcement/ViewAttachments';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import StatusDropDown from 'components/support/StatusDropDown';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useGetAttachedFiles from 'hooks/useGetAttachedFiles';
import useGetResponseForm from 'hooks/useGetResponseForm';
import useRaiseRequest from 'hooks/useRaiseRequest';
import { NAMESPACE } from 'i18n';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import { isJsonString } from 'utils/helper';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import PersonaBaseProtect, { Persona } from '../../components/shared/protected/PersonaBaseProtect';
import { ROUTES } from '../../constants/routes';
import ApplicationContext from '../../context/ApplicationContext';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import DuDateUtilities from '../../utils/DuDateUtilities';
import styles from './ResponseForm.module.css';

function ResponseForm(props) {
    useCheckMasterData();
    const navigate = useNavigate();
    const location = useLocation();
    const { userLang, userProfile } = useContext(ApplicationContext);
    const authId = userProfile.id;
    const path = location.pathname.split('/');
    const rowData = location?.state?.data ? JSON.parse(location.state.data) : {};
    rowData.RequestId = rowData.RequestId ? rowData.RequestId : path[path.length - 2];
    const { currentResponse } = useGetResponseForm(props.oktaAuth, rowData.RequestId);
    const { fileURL, isFileLoading } = useGetAttachedFiles(
        props.oktaAuth,
        currentResponse.ResponseAttachmentURL + ',' + currentResponse.RequestAttachmentURL
    );
    const { raiseRequestHandler } = useRaiseRequest(props.oktaAuth);
    const [status, setStatus] = useState<string>(rowData.Status || '');

    const updateRequest = () => {
        const requestPayload = {
            RequestId: currentResponse.RequestId,
            DistrictId: currentResponse.DistrictId,
            CreatedAt: currentResponse.RequestCreatedAt,
            Status: status || currentResponse.Status,
            RequestBody: currentResponse.RequestBody,
            Priority: currentResponse.Priority,
            RequestHeader: currentResponse.RequestHeader,
            Resolution: '',
            UserLanguage: userLang,
            AttachementURL: currentResponse.RequestAttachmentURL,
            FileType: ''
        };
        raiseRequestHandler(requestPayload);
    };

    const goBackHandler = () => {
        navigate(`/${ROUTES.SUPPORT}`);
    };

    return (
        <DrcContainer heading="Response" goBackHandler={goBackHandler}>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item container alignItems="center" justifyContent="space-between" xs={12}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TypographyTranslated nameSpacing={NAMESPACE.SUPPORT} variant="subtitle2" className={styles.subject}>
                                        {'Raised By:'}
                                    </TypographyTranslated>
                                </Grid>
                                <Grid item sx={{ display: 'flex', gap: 30 }}>
                                    <Typography variant="subtitle2">{currentResponse.RequestCreatedBy}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TypographyTranslated nameSpacing={NAMESPACE.SUPPORT} variant="subtitle2" className={styles.subject}>
                                        {'Raised On:'}
                                    </TypographyTranslated>
                                </Grid>
                                <Grid item sx={{ display: 'flex', gap: 30 }}>
                                    <TypographyTranslated variant="subtitle2">
                                        {DuDateUtilities.ToPrettyDateTime(currentResponse.RequestCreatedAt)}
                                    </TypographyTranslated>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TypographyTranslated nameSpacing={NAMESPACE.COMMON} variant="subtitle2" className={styles.subject}>
                                {'Status'}
                            </TypographyTranslated>{' '}
                            <span>{currentResponse.Status}</span>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    {showAttachmentFeature() === true && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ViewAttachments fileURL={fileURL} isFileLoading={isFileLoading} />
                                </Grid>
                            </Grid>
                            <Divider className={styles.divider} />
                        </>
                    )}
                    <Grid container spacing={2}>
                        <Grid item>
                            <TypographyTranslated nameSpacing={NAMESPACE.SUPPORT} variant="subtitle2" className={styles.subject}>
                                {'Last Response'}
                            </TypographyTranslated>
                        </Grid>
                        <Grid item sx={{ display: 'flex', gap: 30 }}>
                            <TypographyTranslated variant="subtitle2">{currentResponse.ResponseBody}</TypographyTranslated>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TypographyTranslated nameSpacing={NAMESPACE.COMMON} variant="subtitle2" className={styles.subject}>
                                {'Responded By'}
                            </TypographyTranslated>
                        </Grid>
                        <Grid item sx={{ display: 'flex', gap: 30 }}>
                            <TypographyTranslated variant="subtitle2">{currentResponse.ResponseUpdatedBy}</TypographyTranslated>
                        </Grid>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Grid spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                        <Typography className={styles.subject}>{currentResponse.RequestHeader}</Typography>
                        {currentResponse.RequestBody && isJsonString(currentResponse.RequestBody) ? (
                            <DriscollsRichText defaultValue={currentResponse.RequestBody} readOnly={true} />
                        ) : (
                            <Typography className={styles.query}>{currentResponse.RequestBody}</Typography>
                        )}
                    </Grid>
                    <Grid xs={11} sm={11} md={11} display="flex" justifyContent="right">
                        <PersonaBaseProtect
                            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                            featureName={FEATURES.RESPONSE_STATUS_UPDATE}
                            access="Write"
                        >
                            <StatusDropDown
                                status={status.toString()}
                                onChange={(value) => setStatus(value)}
                                disabled={currentResponse.RequesterAuthId !== authId}
                            />
                            <TranslatedDrcButton
                                nameSpacing={NAMESPACE.COMMON}
                                isPrimary
                                onClick={updateRequest}
                                style={{ height: '48px' }}
                                disabled={currentResponse.RequesterAuthId !== authId || currentResponse.Status === status}
                            >
                                {'Update Status'}
                            </TranslatedDrcButton>
                        </PersonaBaseProtect>
                    </Grid>
                </Box>
            </Container>
        </DrcContainer>
    );
}

export default withOktaAuth(ResponseForm);
