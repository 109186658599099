import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DocumentLibrary from './DocumentLibrary';

const ProtectedDocLibrary = () => {
    return (
        <PersonaBaseProtect
            personasAllowed={[Persona.REGIONAL_ADMIN, Persona.PORTAL_USER, Persona.SUPER_ADMIN, Persona.GROWER_USER, Persona.GROWER_ADMIN]}
            featureName={FEATURES.LIBRARY}
            access="Read"
            forbiddenComponent={<DrcPageNotFound />}
        >
            <DocumentLibrary />
        </PersonaBaseProtect>
    );
};

const DocumentLibraryRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ProtectedDocLibrary />} />
            <Route path={ROUTES.DOCUMENTSPARAM} element={<ProtectedDocLibrary />} />
            <Route path={ROUTES.DOCUMENTS_SEARCH} element={<ProtectedDocLibrary />} />
            <Route path={ROUTES.DOCUMENTS_LATEST} element={<ProtectedDocLibrary />} />
        </Routes>
    );
};

export default DocumentLibraryRoutes;
