import { Call } from '@driscollsinc/driscolls-display-rules';
import { TPersonaDetailsType, TUISelection } from '@driscollsinc/users-management-module-ui';
import useGetApiTokens from 'hooks/useGetApiTokens';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import { useState } from 'react';
import { GET_ALL_AVAILABLE_PERSONAS, GET_SELECTED_PERSONA_DETAILS } from 'services/api/v2/PersonaMngmntAPI';
import { PersonaData, PersonaResponseData } from 'types/v2/personamanagement';

const usePersonaDetails = (oktaAuth: any) => {
    const [loading, setLoading] = useState(true);
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [personaData, setPersonaData] = useState<Array<PersonaData> | []>([]);
    const [personaDetails, setPersonaDetails] = useState<TPersonaDetailsType | object>({});
    const [personaTypes, setPersonaTypes] = useState<Array<TUISelection> | []>([]);
    const { getTextTranslated } = useLang();
    const getAvailablePersonas = async (pageNumber?: string) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_ALL_AVAILABLE_PERSONAS(pageNumber ? pageNumber : '1'),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.display && response.display.length > 0) {
                const mappedData: PersonaData[] = response.display.map((item: PersonaResponseData) => ({
                    id: item.persona_id,
                    name: item.persona_name,
                    description: '',
                    displayName: item.persona_name.charAt(0).toUpperCase() + item.persona_name.slice(1)
                }));
                setPersonaData(mappedData);
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchPersonaDetails = async (id: string) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_SELECTED_PERSONA_DETAILS(id),
                    method: 'GET',
                    options: {}
                },
                token
            );

            if (response.display) {
                const personas = [] as TUISelection[];
                setPersonaDetails(response.display);
                Object.entries(response.display).forEach(([_, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach((persona: any) => {
                            const config = persona.Config ? JSON.parse(persona.Config) : null;
                            if (config) {
                                const { type, sections } = config;
                                personas.push({
                                    label: getTextTranslated(persona.PersonaName, NAMESPACE.COMMON),
                                    value: persona.PersonaId,
                                    cognitoGroup: persona.CognitoGroup,
                                    sections,
                                    type
                                });
                            }
                        });
                    }
                    setPersonaTypes(personas);
                });
                return personas;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getPersonaDetails = async (id: string, currentPersonaId: string) => {
        const personas: TUISelection[] = await fetchPersonaDetails(id);
        const currentPersona = personas.find((persona: TUISelection) => persona.value == currentPersonaId);
        return currentPersona;
    };

    return { error, loading, getAvailablePersonas, personaData, personaDetails, fetchPersonaDetails, personaTypes, getPersonaDetails };
};

export default usePersonaDetails;
