import { ButtonVariant, DriscollsLoadingButton, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Icon } from '@iconify/react';
import { withOktaAuth } from '@okta/okta-react';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcDialog from 'components/shared/drc/DrcDialog';
import DrcInformationBox from 'components/shared/drc/DrcInformationBox';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ApplicationContext from 'context/ApplicationContext';
import useGetAnnouncements from 'hooks/useGetAnnouncements';
import useRemoveAnnouncement from 'hooks/useRemoveAnnouncement';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnnouncementGridContainer from '../../components/announcement/AnnouncementGridContainer';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import useLang from '../../hooks/useLang';
import useResponsive from '../../hooks/useResponsive';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import styles from './announcement.module.scss';

function Announcement(props) {
    const [page, setPage] = useState(1);
    const isDesktop = useResponsive('up', 'md');
    const navigate = useNavigate();
    const { userPersona, setShowToast } = useContext(ApplicationContext);
    const [removeAnnouncementDialogOpen, setRemoveAnnouncementDialogOpen] = useState(false);
    const { getTextTranslated } = useLang();
    const [announcementIdToRemove, setAnnouncementIdToRemove] = useState(0);
    const [loading, setLoading] = useState(false);
    const {
        invokeRemoveAnnouncement,
        error: removeAnnouncementError,
        loading: removeAnnouncementLoading
    } = useRemoveAnnouncement(props.oktaAuth, announcementIdToRemove);
    const { announcementList, pageCount, totalAnnouncements, fetchAnnouncements } = useGetAnnouncements(props.oktaAuth, userPersona);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await fetchAnnouncements();
        setLoading(false);
    };

    const removeAnnouncement = (AnnouncementId) => {
        setAnnouncementIdToRemove(AnnouncementId);
        setRemoveAnnouncementDialogOpen(true);
    };

    const removeAnnouncementHandler = async () => {
        await invokeRemoveAnnouncement();
        if (removeAnnouncementError) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
        } else {
            setShowToast({ toastMessage: 'Announcement Removed Successfully!', nameSpacing: NAMESPACE.ANNOUNCEMENT });
        }
        setRemoveAnnouncementDialogOpen(false);
        await fetchAnnouncements();
    };

    return (
        <DrcContainer heading="Announcement">
            {isDesktop ? (
                <div className={styles.headerContainer}>
                    <PersonaBaseProtect
                        personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                        featureName={FEATURES.ANNOUNCEMENT_CREATE}
                        access="Write"
                    >
                        <TranslatedDrcButton
                            nameSpacing={NAMESPACE.ANNOUNCEMENT}
                            isPrimary
                            noStyle
                            endIcon={<Icon icon="material-symbols:mail-outline-sharp" />}
                            onClick={() => navigate('create-announcement')}
                        >
                            {'Create Announcement'}
                        </TranslatedDrcButton>
                    </PersonaBaseProtect>
                </div>
            ) : (
                <div className={styles.headerContainer}>
                    <PersonaBaseProtect
                        personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                        featureName={FEATURES.ANNOUNCEMENT_CREATE}
                        access="Write"
                    >
                        <TranslatedDrcButton
                            nameSpacing={NAMESPACE.ANNOUNCEMENT}
                            isPrimary
                            endIcon={<Icon icon="material-symbols:mail-outline-sharp" />}
                            onClick={() => navigate('create-announcement')}
                        >
                            {'Create Announcement'}
                        </TranslatedDrcButton>
                    </PersonaBaseProtect>
                </div>
            )}
            <AnnouncementGridContainer
                page={page}
                totalUsers={totalAnnouncements}
                announcementList={announcementList}
                pageCount={pageCount}
                removeAnnouncement={removeAnnouncement}
                loading={loading}
            />
            <DrcDialog
                title={getTextTranslated('Are you sure?', NAMESPACE.COMMON)}
                open={removeAnnouncementDialogOpen}
                isConfirmation={true}
                buttons={
                    <>
                        <DriscollsLoadingButton
                            label={getTextTranslated('Cancel', NAMESPACE.COMMON)}
                            variant={ButtonVariant.SECONDARY}
                            onClick={() => setRemoveAnnouncementDialogOpen(false)}
                        />
                        <DriscollsLoadingButton
                            variant={ButtonVariant.PRIMARY}
                            loading={removeAnnouncementLoading}
                            onClick={removeAnnouncementHandler}
                            label={getTextTranslated('Remove', NAMESPACE.COMMON)}
                        />
                    </>
                }
            >
                <DrcInformationBox>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>
                        {'Do you really want to remove the announcement?'}
                    </TypographyTranslated>
                </DrcInformationBox>
            </DrcDialog>
        </DrcContainer>
    );
}

export default withOktaAuth(Announcement);
