import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import logo from 'Images/Logo_Small_Transparent.png';
import { setMasterDataInitialized } from 'actions/MasterActions';
import style from 'components/appinitializer/AppInitializer.module.scss';
import FailedApiComponent from 'components/appinitializer/FailedApiComponent';
import InvalidPersonaComponent from 'components/appinitializer/InvalidPersonaComponent';
import MasterDataLoader from 'components/appinitializer/MasterDataLoader';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import useGetInitialAppData from 'hooks/useGetInitialAppData';
import useGetLoadMasterData from 'hooks/useGetLoadMasterData';
import useMakeDefaultPersona from 'hooks/useMakeDefaultPersona';
import useConfig from 'hooks/v2/useConfig';
import { Navigate } from 'react-router-dom';

const AppInitializer = (props) => {
    const { oktaAuth } = props;
    const { oktaAuth: okta } = useOktaAuth();
    const dispatch = useDispatch();
    const { userSetup, setUserSetup } = useContext(ApplicationContextV2);
    const redirectUrl = useSelector((state: any) => state.masterReducer.redirectUrl);
    const [forceloadApp, setForceLoadApp] = useState<boolean>(false);
    const { makeDefaultPersona } = useMakeDefaultPersona(oktaAuth);
    const { loadPersonas, userPersonas, userPersona, landingPage, invalidPersona, defaultPersonaName, inactiveUser, initializeRedirectUrl } =
        useGetInitialAppData(oktaAuth, redirectUrl);
    const { getUserFeaturesByPersona } = useConfig();

    const {
        loadMasterData,
        isMasterDataInitialized,
        userStatus,
        basicInformation,
        userProfile,
        userFeatures,
        companies,
        ranches,
        districtBerries,
        districtOptions,
        totalCount,
        errorCount,
        successCount,
        message,
        userLang
    } = useGetLoadMasterData(oktaAuth);

    const init = async () => {
        const v2Enabled = window.config.uiConfig.v2UI;
        if (v2Enabled) {
            const setup = await getUserFeaturesByPersona(okta, userPersona?.toString());
            setUserSetup(setup);
        }
        if (userPersona) {
            loadMasterData(userPersona);
        }

    }
    useEffect(() => {
        init();

    }, [userPersona]);

    const changeDefaultPersona = async (persona: Persona) => {
        await makeDefaultPersona(persona);
        await loadPersonas();
    };

    /**
     * @description Allow the application to load even if the primary data is
     * not loaded completely
     */
    const forceLoadApp = () => {
        setForceLoadApp(true);
    };

    useEffect(() => {
        if (isMasterDataInitialized || forceloadApp) {
            props.setPersona(userPersona);
            props.setLandingPage(landingPage);
            props.setUserPersonas(userPersonas);
            props.setCompanies(companies);
            props.setRanches(ranches);
            props.setDistrictOptions(districtOptions);
            props.setDistrictBerries(districtBerries);
            props.setBasicInformation(basicInformation);
            props.setUserProfile(userProfile);
            props.setUserStatus(userStatus);
            props.setUserLang(userLang);
            props.setUserFeatures(userFeatures);

            //TODO once the context api is set we need to enable this
            props.setMasterDataInitialized(isMasterDataInitialized || forceloadApp);

            //Redux
            //TODO remove once we implement the context across modules
            dispatch(setMasterDataInitialized(isMasterDataInitialized || forceloadApp));
        }
    }, [isMasterDataInitialized, forceloadApp]);

    const percentComplete = Math.ceil(((successCount + errorCount) / totalCount) * 100);
    if (isMasterDataInitialized || forceloadApp) {
        console.log('INIT DATA ', redirectUrl, initializeRedirectUrl, landingPage)
        return <Navigate to={(redirectUrl && redirectUrl !== 'undefined' ? redirectUrl : initializeRedirectUrl) || initializeRedirectUrl || landingPage} />;

    } else if (inactiveUser) {
        return <Navigate to={ROUTES.INACTIVE_USER} />

    } else {
        return (
            <section>
                <img className={style.logo} src={logo} alt="drc logo" />
                {invalidPersona && invalidPersona.length > 0 ? (
                    <InvalidPersonaComponent
                        makeDefaultPersona={(persona) => changeDefaultPersona(persona)}
                        defaultPersonaName={defaultPersonaName}
                        invalidPersona={invalidPersona}
                    />
                ) : !inactiveUser ? (
                    <MasterDataLoader percentComplete={percentComplete} message={message} />
                ) : null}
                {successCount + errorCount >= totalCount && errorCount > 0 && (
                    <FailedApiComponent errorCount={errorCount} totalCount={totalCount} forceLoadApp={forceLoadApp} />
                )}
            </section>
        );
    }
};

export default withOktaAuth(AppInitializer);
