export interface IPersonaFeatures {
    [FEATURES.DASHBOARD]?: { Read: boolean; Write: boolean };
    [FEATURES.DASHBOARD_CREATE_EVENTS]?: { Read: boolean; Write: boolean };
    [FEATURES.DASHBOARD_CONTACT]?: { Read: boolean; Write: boolean };
    [FEATURES.DASHBOARD_REPORT_BUILDER]?: { Read: boolean; Write: boolean };
    [FEATURES.USER_MANAGEMENT]?: { Read: boolean; Write: boolean };
    [FEATURES.USER_MANAGEMENT_INVITE_USER]?: { Read: boolean; Write: boolean };
    [FEATURES.USER_MANAGEMENT_EDIT_ASSIGNMENTS]?: { Read: boolean; Write: boolean };
    [FEATURES.USER_MANAGEMENT_EDIT_PERSONA_PERUSER]?: { Read: boolean; Write: boolean };
    [FEATURES.USER_MANAGEMENT_DELETE_USER]?: { Read: boolean; Write: boolean };
    [FEATURES.USER_MANAGEMENT_DELETE_PERSONA]?: { Read: boolean; Write: boolean };
    [FEATURES.PTI]?: { Read: boolean; Write: boolean };
    [FEATURES.REPORTS]?: { Read: boolean; Write: boolean };
    [FEATURES.ANNOUNCEMENTS]?: { Read: boolean; Write: boolean };
    [FEATURES.ANNOUNCEMENT_CREATE]?: { Read: boolean; Write: boolean };
    [FEATURES.LIBRARY]?: { Read: boolean; Write: boolean };
    [FEATURES.SUPPORT]?: { Read: boolean; Write: boolean };
    [FEATURES.SUPPORT_CREATE]?: { Read: boolean; Write: boolean };
    [FEATURES.SUPPORT_REPLY]?: { Read: boolean; Write: boolean };
    [FEATURES.ANALYTICS]?: { Read: boolean; Write: boolean };
    [FEATURES.WEATHER]?: { Read: boolean; Write: boolean };
    [FEATURES.BERRY_CONNECT]?: { Read: boolean; Write: boolean };
    [FEATURES.RAISE_AN_ISSUE]?: { Read: boolean; Write: boolean };
    [FEATURES.ANNOUNCEMENT_DELETE]?: { Read: boolean; Write: boolean };
    [FEATURES.UPLOAD_REPORTS]?: { Read: boolean; Write: boolean };
    [FEATURES.RESPONSE_STATUS_UPDATE]?: { Read: boolean; Write: boolean };
    [FEATURES.FEATURE_MANAGEMENT]?: { Read: boolean; Write: boolean };
    [FEATURES.PERSONA_MANAGEMENT]?: { Read: boolean; Write: boolean };
    [FEATURES.REPORT_BUILDER]?: { Read: boolean; Write: boolean };
    [FEATURES.DASHBOARD_EVENTS]?: { Read: boolean; Write: boolean };
    [FEATURES.ENTITY_ASSIGNMENT]?: { Read: boolean; Write: boolean };
    [FEATURES.BUILD_REPORT]?: { Read: boolean; Write: boolean };
}

export enum FEATURES {
    DASHBOARD = 'dashboard',
    DASHBOARD_CREATE_EVENTS = 'dashboardCreateEvents',
    DASHBOARD_CONTACT = 'dashboardContact',
    DASHBOARD_REPORT_BUILDER = 'dashboardReportBuilder',
    USER_MANAGEMENT = 'userManagement',
    USER_MANAGEMENT_INVITE_USER = 'userManagementInviteUser',
    USER_MANAGEMENT_EDIT_ASSIGNMENTS = 'userManagementEditAssignments',
    USER_MANAGEMENT_EDIT_PERSONA_PERUSER = 'userManagementEditPersonaPerUser',
    USER_MANAGEMENT_DELETE_USER = 'userManagementDeleteUser',
    USER_MANAGEMENT_DELETE_PERSONA = 'userManagementDeletePersona',
    PTI = 'pti',
    REPORTS = 'reports',
    ANNOUNCEMENTS = 'announcements',
    ANNOUNCEMENT_CREATE = 'announcementCreate',
    LIBRARY = 'library',
    SUPPORT = 'support',
    SUPPORT_CREATE = 'supportCreate',
    SUPPORT_REPLY = 'supportReply',
    ANALYTICS = 'analytics',
    WEATHER = 'weather',
    BERRY_CONNECT = 'berryConnect',
    REPORT_BUILDER = 'reportBuilder',
    DASHBOARD_EVENTS = 'dashboardEvents',
    RAISE_AN_ISSUE = 'raiseAnIssue',
    ANNOUNCEMENT_DELETE = 'announcementDelete',
    UPLOAD_REPORTS = 'uploadReports',
    RESPONSE_STATUS_UPDATE = 'responseStatusUpdate',
    FEATURE_MANAGEMENT = 'featureManagement',
    PERSONA_MANAGEMENT = 'personaManagement',
    ENTITY_ASSIGNMENT = 'entityAssignment',
    BUILD_REPORT = 'buildReport'
}
