export enum routesName {
    USERS = 'users',
    ADD_PERSONA = 'addPersona',
    EDIT_USER_PERSONA = 'editUserPersona',
    INVITE_USER = 'invite',
    EDIT_FEATURE = 'create',
    INITIALIZE_APPLICATION = 'initializeApplication',
    ENTITY_ASSIGNMENT = 'entityAssignment'
}

export const routes = {
    [routesName.INITIALIZE_APPLICATION]: {
        path: '/InitializeApplication/',
        getRoute: () => '/InitializeApplication/'
    },
    [routesName.ADD_PERSONA]: {
        path: 'addPersona/user/:userId',
        getRoute: (userId: string, personaIds: string, userType: string) => `addPersona/user/${userId}?personaIds=${personaIds}&userType=${userType}`
    },
    [routesName.EDIT_USER_PERSONA]: {
        path: 'edit/user/:userId/persona/:personaId',
        getRoute: (userId: string, personaId: string) => `edit/user/${userId}/persona/${personaId}`
    },
    [routesName.INVITE_USER]: {
        path: 'invite',
        getRoute: () => 'invite'
    },
    [routesName.EDIT_FEATURE]: {
        path: 'create/:featureId',
        getRoute: (featureId: string) => `create/${featureId}`
    },
    [routesName.ENTITY_ASSIGNMENT]: {
        path: 'assignment/entity/:EntityName',
        getRoute: (EntityName: string) => `assignment/entity/${EntityName}`
    }
};
