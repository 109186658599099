import { Call } from '@driscollsinc/driscolls-display-rules';
import { TUIReports } from '@driscollsinc/users-management-module-ui';
import { ROUTES } from 'constants/routes';
import useGetApiTokens from 'hooks/useGetApiTokens';
import { useState } from 'react';
import { GET_ASSIGNED_REPORTS, GET_REPORTS_BY_CATEGORY, GET_REPORTS_LIST } from 'services/api/v2/ReportsAPI';

const DAILY_WEEKLY_REPORT_ID = 30;
export const GROWER_SETTLEMENT_REPORT_ID = 31;
export const SUPPLEMENTAL_STATEMENT_REPORT_ID = 33;
export const CANADA_MANUAL_TAX_REPORT_ID = 34;

const useReports = (oktaAuth) => {
    const { getTokenAndId } = useGetApiTokens();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchReports = async (userLang: string, canViewReports: boolean, boolReportByCategory: boolean, editUserOktaId?: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        let url = '';
        if (boolReportByCategory) {
            url = GET_REPORTS_BY_CATEGORY();
        } else if (canViewReports) {
            url = GET_REPORTS_LIST(userLang);
        } else {
            url = GET_ASSIGNED_REPORTS(editUserOktaId, userLang);
        }
        try {
            const response = await Call(
                {
                    name: 'Reports',
                    url: url,
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.display) {
                const result = response.display.map((report) => ({
                    ...report,
                    label: report.ReportName,
                    Description: getReportDescription(report.ReportId)
                }));

                const mappedData: Array<TUIReports> = [];
                result.map((report) => {
                    mappedData.push({ label: report.label, value: report.ReportId, reportUrl: report.ReportURL });
                });
                return mappedData;
            }
        } catch (error) {
            setError(error);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getReportDescription = (reportId) => {
        switch (reportId) {
            case GROWER_SETTLEMENT_REPORT_ID:
                return ROUTES.VIEW_GROWER_SETTLEMENT_REPORT;
            case SUPPLEMENTAL_STATEMENT_REPORT_ID:
                return ROUTES.VIEW_SUPPLEMENTAL_STATEMENT_REPORT;
            case CANADA_MANUAL_TAX_REPORT_ID:
                return ROUTES.VIEW_CANADA_MANUAL_TAX_REPORT;
            case DAILY_WEEKLY_REPORT_ID:
                return ROUTES.VIEW_WEEKLY_REPORT;
            default:
                return null;
        }
    };

    return { fetchReports, error, loading };
};

export default useReports;
