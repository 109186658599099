import { MoreVert } from '@mui/icons-material';
import { Avatar, Divider, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { NAMESPACE } from 'i18n';
import React, { useState } from 'react';
import { isJsonString } from 'utils/helper';
import { ROUTES } from '../../constants/routes';
import useLang from '../../hooks/useLang';
import styles from './SupportListItemRenderer.module.css';
const SupportListItemRenderer = ({ row, i, navigate }) => {
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const open = Boolean(openSubMenu);
    const obj = JSON.stringify(row);
    const { getTextTranslated } = useLang();
    return (
        <>
            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={(e) => setOpenSubMenu(e.currentTarget)}>
                        <MoreVert />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <Avatar alt={row.email} />
                </ListItemAvatar>
                <ListItemText
                    primary={(row.FirstName || '') + ' ' + (row.LastName || '')}
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        letterSpacing: 0
                    }}
                    secondary={
                        <Stack spacing={1}>
                            <Typography className={styles.items} sx={{ fontWeight: 'bolder' }} component="span" variant="body2" color="text.primary">
                                {row.RequesterUserName}
                            </Typography>
                            <Typography className={styles.items} sx={{ fontWeight: 'bold' }} component="span" variant="body2" color="text.primary">
                                {row.RequestHeader}
                            </Typography>
                            <Typography className={styles.items} sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {isJsonString(row?.RequestBody)
                                    ? JSON.parse(row.RequestBody)
                                          .blocks.map((itm) => itm.text)
                                          .join(' ')
                                    : row.RequestBody}
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <Menu
                id="basic-menu"
                anchorEl={openSubMenu}
                open={open}
                onClose={() => setOpenSubMenu(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]} featureName={FEATURES.SUPPORT} access='Read'>
                    <MenuItem
                        onClick={() =>
                            navigate(`${ROUTES.RESOLUTION}/${row.RequestId}/${row.ResponseId ? row.ResponseId : null}`, {
                                state: { data: obj }
                            })
                        }
                    >
                        {getTextTranslated('Reply', NAMESPACE.EVENTS)}
                    </MenuItem>
                </PersonaBaseProtect>
                <PersonaBaseProtect personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN]} featureName={FEATURES.SUPPORT} access='Read'>
                    <MenuItem
                        onClick={() =>
                            navigate(`${ROUTES.VIEW_RESPONSE}/${row.RequestId}/${row.ResponseId ? row.ResponseId : null}`, {
                                state: { data: obj }
                            })
                        }
                    >
                        {getTextTranslated('View', NAMESPACE.EVENTS)}
                    </MenuItem>
                </PersonaBaseProtect>
            </Menu>
        </>
    );
};

export default SupportListItemRenderer;
