// @ts-nocheck
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import React, { Suspense } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ThemeProvider from './theme';

// local imports
import DrcBackdrop from './components/shared/drc/DrcBackdrop';
import DrcDialog from './components/shared/drc/DrcDialog';
import DrcImplicitCallback from './components/shared/drc/DrcImplicitCallback';
import DrcMain from './components/shared/drc/DrcMain';
import DrcPageNotFound from './components/shared/drc/DrcPageNotFound';
import { ROUTES } from './constants/routes';
import TranslatedDrcButton from './ui/translatedButton/TranslatedDrcButton';

import MuiAlert from '@mui/material/Alert';
import { Amplify } from 'aws-amplify';
import InactiveUserComponent from 'components/appinitializer/InactiveUserComponent';
import GEPToast from 'components/toast/GEPToast';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { createHashHistory } from 'history';
import { NAMESPACE } from 'i18n';
import AnalyticsRoutes from 'pages/analytics/AnalyticsRoutes';
import AnnouncementRoutes from 'pages/announcement/AnnouncementRoutes';
import BerryConnectRoutes from 'pages/berryconnect/BerryConnectRoutes';
import InitData from 'pages/common/InitData';
import DashboardRoutes from 'pages/dashboard/DashboardRoutes';
import DocumentLibraryRoutes from 'pages/documentlibrary/DocumentLibraryRoutes';
import EventsRoutes from 'pages/events/EventsRoutes';
import FeedbackRoutes from 'pages/feedback/FeedbackRoutes';
import LoginPage from 'pages/login/LoginPage';
import ReportsRoutes from 'pages/report/ReportsRoutes';
import SupportRoutes from 'pages/support/SupportRoutes';
import UserManagementRoutes from 'pages/usermanagement/UserManagementRoutes';
import UserManagementRoutesV2 from 'pages/v2/UserManagement/UserManagementRoutes';
import FeatureManagementRoutes from 'pages/v2/featuremanagement/FeatureManagementRoutes';
import PersonaManagementRoutes from 'pages/v2/personamanagement/PersonaManagementRoutes';
import InactivityTracker from 'utils/activityTracker';
import BackgroundPng from './Images/Fancy_Rasp.png';
import BackgroundWebP from './Images/Fancy_Rasp.webp';
import { setInitializeRedirectUrl } from './actions/MasterActions';
import { serviceWorkerUpdate } from './actions/actions';
import { TypographyTranslated } from './components/shared/Typography/TypographyTranslated';
import DuAuthenticationUtilities from './components/shared/Utilities/DuAuthenticationUtilities';
import CustomGridOverlay from './components/shared/gridoverlays/CustomGridOverlay';
import ProtectedRoute from './components/shared/protected/ProtectedRoute';
import { EventConstants } from './constants/EventConstants';
import LoggingUtilities from './data/LoggingUtilities';
import MasterDataUtilities from './data/MasterDataUtilities';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import ApplicationLayout from './layouts/dashboard/v2/ApplicationLayout';
import LogOut from './pages/common/LogOut';
import UserProfile from './pages/profile/UserProfile';
import PrintRoutes from './pages/ptic/PrintRoutes';
import WeatherRoutes from './pages/weather/WeatherRoutes';
import KioskMode from './utils/kioskMode';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

declare global {
    interface Window {
        config: any;
    }
}

const allGroups = [...window.config.OKTA_USER_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];

class App extends React.Component<any, any> {
    history: any;
    constructor(props) {
        super(props);
        this.history = createHashHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        //Cognito Setup
        Amplify.configure({
            Auth: {
                region: window.config.aws_project_region,
                userPoolId: window.config.aws_user_pools_id,
                userPoolWebClientId: window.config.aws_user_pools_client_id,
                authenticationFlowType: 'CUSTOM_AUTH'
            }
        });

        this.initializeAnalytics();

        this.props.setInitializeRedirectUrl(
            window.location.pathname.length > 1 && !window.location.pathname.includes(ROUTES.LOGIN) ? window.location.pathname : undefined // to redirect to landing page based on user persona on INITIALIZE APPLICATION
        );

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
    }

    initializeAnalytics = () => {
        const firebaseConfig = {
            apiKey: window.config.ga.apiKey,
            authDomain: window.config.ga.authDomain,
            projectId: window.config.ga.projectId,
            storageBucket: window.config.ga.storageBucket,
            messagingSenderId: window.config.ga.messagingSenderId,
            appId: window.config.ga.appId,
            measurementId: window.config.ga.measurementId
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        window.config.logScreeView = (screen) => {
            logEvent(analytics, 'screen_view', {
                firebase_screen: screen,
                firebase_screen_class: screen
            });
        };
        window.config.logEvent = async (event, eventInfo) => {
            const token = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(this.oktaAuth);
            const loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            if (eventInfo && eventInfo.appVersion) {
                eventInfo.appVersion = process.env.REACT_APP_VERSION;
                eventInfo.loggedInUser = loggedInUser;
            }
            logEvent(analytics, event, eventInfo);
        };
        window.config.setGAUserId = (userId) => {
            setUserId(analytics, userId);
        };
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    componentDidMount() {
        const orgPushState = window.history.pushState;
        window.history.pushState = function () {
            // eslint-disable-next-line prefer-rest-params
            window.config.logScreeView(arguments[2]);
            // eslint-disable-next-line prefer-rest-params
            orgPushState.apply(this, arguments);
        };
        window.onerror = (msg, url, line, col, error) => {
            console.log('Error: ' + msg + '\nurl: ' + url + '\nline: ' + line);
            window.config.logEvent(EventConstants.WEB_APP_ERROR, { error: msg });
        };
    }

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        return (
            <ThemeProvider>
                <BrowserRouter history={BrowserRouter.browserHistory}>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <Suspense fallback={<CustomGridOverlay loadingMessage={'Loading'} />} />
                                </DrcMain>
                            }
                        >
                            <Routes>
                                <Route
                                    path={ROUTES.ROOT}
                                    element={window.config.uiConfig.v2UI === 'true' ? <ApplicationLayout /> : <DashboardLayout />}
                                >
                                    <Route path={`${ROUTES.ANALYTICS}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<AnalyticsRoutes />} />
                                    <Route path={`${ROUTES.DASHBOARD}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<DashboardRoutes />} />
                                    <Route
                                        path={`${ROUTES.USER_MANAGEMENT}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`}
                                        element={window.config.uiConfig.v2UserManagement === 'true' ? <UserManagementRoutesV2 /> : <UserManagementRoutes />}
                                    />
                                    <Route
                                        path={`${ROUTES.PERSONA_MANAGEMENT}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`}
                                        element={<PersonaManagementRoutes />}
                                    />
                                    <Route
                                        path={`${ROUTES.FEATURE_MANAGEMENT}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`}
                                        element={<FeatureManagementRoutes />}
                                    />
                                    <Route path={`${ROUTES.REPORTS}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<ReportsRoutes />} />
                                    <Route path={`${ROUTES.ANNOUNCEMENT}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<AnnouncementRoutes />} />
                                    <Route path={`${ROUTES.EVENTS}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<EventsRoutes />} />
                                    <Route
                                        path={`${ROUTES.DOCUMENT_LIBRARY}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`}
                                        element={<DocumentLibraryRoutes />}
                                    />
                                    <Route path={`${ROUTES.FEEDBACK}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<FeedbackRoutes />} />
                                    <Route path={`${ROUTES.PTI}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<PrintRoutes />} />
                                    <Route path={`${ROUTES.SUPPORT}${ROUTES.SEPARATOR}${ROUTES.WILDCARD}`} element={<SupportRoutes />} />
                                    <Route
                                        path={ROUTES.PROFILE}
                                        element={
                                            <ProtectedRoute>
                                                <UserProfile />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route path={ROUTES.WEATHER} exact element={<WeatherRoutes />} />
                                    <Route path={ROUTES.BERRY_CONNECT} exact element={<BerryConnectRoutes />} />
                                </Route>
                                <Route path={ROUTES.INITIALIZE_APP} element={<InitData />} groupsAllowed={allGroups} />
                                <Route path={ROUTES.INACTIVE_USER} element={<InactiveUserComponent />} groupsAllowed={allGroups} />
                                <Route index path={ROUTES.LOGIN} element={<LoginPage />} />
                                <Route
                                    path={ROUTES.OKTA_CALLBACK}
                                    element={<DrcImplicitCallback backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />}
                                />
                                <Route path={ROUTES.LOGOUT} exact element={<LogOut />} />
                                <Route path={ROUTES.SETKIOSK} exact element={<KioskMode />} />
                                <Route path={ROUTES.WILDCARD} element={<DrcPageNotFound />} />
                            </Routes>
                        </Suspense>
                    </Security>
                    <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingScreenMessage} />
                    <DrcDialog
                        title={'New version available'}
                        open={this.props.serviceWorkerUpdated}
                        buttons={
                            <>
                                <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} onClick={this.closeNewVersionDialog}>
                                    {'Close'}
                                </TranslatedDrcButton>
                                <TranslatedDrcButton nameSpacing={NAMESPACE.COMMON} isPrimary onClick={this.updateServiceWorker}>
                                    {'Ok'}
                                </TranslatedDrcButton>
                            </>
                        }
                    >
                        <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'Refresh to update your app?'}</TypographyTranslated>
                    </DrcDialog>
                    <InactivityTracker
                        inActivityEvent={() => {
                            window.location.href = '/logOut';
                        }}
                        inactivePeriod={window.config.kioskInactiveTime ?? 150000}
                    />
                </BrowserRouter>
                <GEPToast />
            </ThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        pageTitle: state.rootReducer.pageTitle,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate()),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl))
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(App));
