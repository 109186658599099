import { useLang as styleGuideUseLang } from '@driscollsinc/style-guide-web2.0';
import { TUIReports } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import useGetApiTokens from 'hooks/useGetApiTokens';
import useLang from 'hooks/useLang';
import useReports from 'hooks/v2/useReports';
import { NAMESPACE } from 'i18n';
import { useContext } from 'react';
import { ReportResponse } from 'types/v2/reports';
const useUserManagementService = () => {
    const { oktaAuth } = useOktaAuth();
    const { getTokenAndId } = useGetApiTokens();
    const { getCurrentLanguageSelected } = useLang();
    const { fetchReports } = useReports(oktaAuth);
    const { companyEntities, countries, districtEntities, userSetup } = useContext(ApplicationContextV2);
    const isLoggedInUserInternalAdmin = ['Super Admin', 'Regional Admin'].includes(userSetup.PersonaName);
    const { getTextTranslated } = styleGuideUseLang([NAMESPACE.USERS]);
    const fetchLanguages = async () => {
        return [
            { value: 'en', label: getTextTranslated('English', NAMESPACE.USERS) },
            { value: 'es', label: getTextTranslated('Spanish', NAMESPACE.USERS) },
            { value: 'fr', label: getTextTranslated('French', NAMESPACE.USERS) }
        ];
    };

    const fetchCountries = async () => {
        return countries.map((country) => ({ value: country.Code, label: country.Name, ...country }));
    };

    const fetchRoles = async () => {
        return [
            { value: '1', label: 'Controller/Accountant' },
            { value: '2', label: 'Grower Owner' },
            { value: '3', label: 'President' },
            { value: '4', label: 'Production Assistant' },
            { value: '5', label: 'Ranch Assistant' },
            { value: '6', label: 'Ranch Foreman' },
            { value: '7', label: 'Ranch Manager' },
            { value: '8', label: 'Truck Driver' },
            { value: '9', label: 'Other' }
        ];
    };

    const initPersonalInfo = async () => {
        const preferredLanguage = await fetchLanguages();
        const countries = await fetchCountries();
        const role = await fetchRoles();
        const personalInfo = {
            userType: '',
            preferredLanguage,
            countries,
            role,
            defaultUser: [
                { value: 'phone', label: getTextTranslated('Phone', NAMESPACE.USERS) },
                { value: 'email', label: getTextTranslated('Email', NAMESPACE.USERS) }
            ]
        };
        return personalInfo;
    };

    // TODO: based on the type of assignment
    const initAssignmentsSetup = async (sections) => {
        if (sections && sections.assignments) {
            const { assignments } = sections;
            return {
                assignments: {
                    selectionType: assignments.selectionType,
                    showCompanies: assignments.showCompanies ?? assignments.showRanches,
                    showDistrictCommodity: assignments.showDistrictCommodity
                },
                entitiesAvailable: assignments.selectionType === 'district' ? districtEntities : companyEntities
            };
        } else {
            return {
                assignments: {}
            };
        }
    };

    const initSetup = async (sections, type?: { external: boolean; internal: boolean }) => {
        const { userOktaId } = await getTokenAndId(oktaAuth);
        const personalInfo = await initPersonalInfo();
        personalInfo.userType = type?.external ? 'external' : 'internal';
        const { assignments, entitiesAvailable } = await initAssignmentsSetup(sections);
        const reportsList = await fetchReports(
            getCurrentLanguageSelected(),
            isLoggedInUserInternalAdmin,
            false,
            !isLoggedInUserInternalAdmin ? userOktaId : null
        );
        // TODO: add logic her for entity type
        const setUp = { personalInfo, reports: reportsList, entitiesAvailable, assignments };
        return setUp;
    };

    const getAlreadySelectedReport = (reportsSelected: Array<ReportResponse>) => {
        const ret: Array<TUIReports> = [];
        if (reportsSelected) {
            reportsSelected.map((report) => {
                ret.push({ value: report.ReportId, label: report.ReportName, reportUrl: report.ReportURL });
            });
        }
        return ret;
    };

    const initInviteUserSetup = async (sections, type?: { external: boolean; internal: boolean }) => {
        try {
            const setUp = await initSetup(sections, type);
            return { error: null, setUp };
        } catch (error) {
            return { error: 'ERROR-1001', setUp: null };
        }
    };

    return { initInviteUserSetup, getAlreadySelectedReport };
};

export default useUserManagementService;
